<template>
  <div class="form-apply-page wrapper page-wrap">
    <a-spin :spinning="loadingForm">
      <a-form
        :model="form"
        ref="formRef"
        layout="vertical"
        :colon="colon"
        :rules="rules"
        @finish="handleSubmit"
      >
        <div class="form-wrap">
          <a-row type="flex" justify="space-between" :gutter="20">
            <a-col :span="12">
              <a-form-item name="enterprise_name" required label="企业名称">
                <a-input
                  v-model:value="form.enterprise_name"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_year" label="年度">
                <a-input v-model:value="form.form_year" readonly size="large" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_month" label="月份">
                <a-input
                  v-model:value="form.form_month"
                  readonly
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_rysj" label="入园时间">
                <a-date-picker
                  size="large"
                  picker="date"
                  v-model:value="form.form_rysj"
                  format="YYYY-MM-DD"
                  valueFormat="YYYY-MM-DD"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_bgmj" label="办公面积（㎡）">
                <a-input-number
                  v-model:value="form.form_bgmj"
                  :min="0"
                  :precision="2"
                  style="width: 100%"
                  placeholder="请输入办公面积（㎡）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_bgrs" label="办公人数">
                <a-input-number
                  v-model:value="form.form_bgrs"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入办公人数"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="form_yysr"
                label="营业收入（含代收代付）(万元)"
              >
                <a-input-number
                  v-model:value="form.form_yysr"
                  :min="0"
                  :max="10000"
                  :precision="2"
                  style="width: 100%"
                  placeholder="请输入营业收入（含代收代付）(万元)"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_nse" label="纳税额 （万元）">
                <a-input-number
                  v-model:value="form.form_nse"
                  :min="0"
                  :max="10000"
                  :precision="2"
                  style="width: 100%"
                  placeholder="请输入纳税额 （万元）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_fwrc" label="服务人次（人次）">
                <a-input-number
                  v-model:value="form.form_fwrc"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入服务人次（人次）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_fwdw" label="	服务用人单位（家次）">
                <a-input-number
                  v-model:value="form.form_fwdw"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入服务用人单位（家次）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_zphhj" label="举办招聘会场次（场次）合计">
                <a-input-number
                  v-model:value="form.form_zphhj"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入举办招聘会场次（场次）合计"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_zphxs" label="其中线上招聘会场次（场次）">
                <a-input-number
                  v-model:value="form.form_zphxs"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入其中线上招聘会场次（场次）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_zphxx" label="其中线下招聘会场次（场次）">
                <a-input-number
                  v-model:value="form.form_zphxx"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入其中线下招聘会场次（场次）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_qzxx" label="发布求职信息条数（条）">
                <a-input-number
                  v-model:value="form.form_qzxx"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入发布求职信息条数（条）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_jygw" label="提供就业岗位（个）">
                <a-input-number
                  v-model:value="form.form_jygw"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入提供就业岗位（个）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_jyyx" label="达成就业意向（人）">
                <a-input-number
                  v-model:value="form.form_jyyx"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入达成就业意向（人）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_jyrs" label="带动就业人数（人）">
                <a-input-number
                  v-model:value="form.form_jyrs"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入带动就业人数（人）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_pxb" label="举办培训班场次（场次）">
                <a-input-number
                  v-model:value="form.form_pxb"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入举办培训班场次（场次）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_pxrs" label="	培训人数（人）">
                <a-input-number
                  v-model:value="form.form_pxrs"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入培训人数（人）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item name="form_gccrc" label="高层次人才（含高校毕业生）">
                <a-input-number
                  v-model:value="form.form_gccrc"
                  :min="0"
                  style="width: 100%"
                  placeholder="请输入高层次人才（含高校毕业生）"
                  size="large"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                name="form_attachment"
                label="附件(完税证明、资产负债表、利润表等相关税务报表)"
                :rules="{
                  required: true,
                  message: '请上传附件',
                }"
              >
                <upload-image
                  v-model:imageList="form.form_attachment"
                  :imageCount="9"
                  :couldRename="false"
                ></upload-image>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item class="">
                <a-button
                  block
                  size="large"
                  type="primary"
                  html-type="submit"
                  class="btn"
                  :loading="loadingBtn"
                  @click="handleSubmit"
                >
                  确认提交
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
  createVNode,
} from "@vue/runtime-core";
import { updateEnterpriseFormData, getEnterpriseFormData } from "@/api/main.js";
import { getUserId, getUserData } from "@/utils/storeData.js";
import url from "@/utils/url.js";
import { message, Modal } from "ant-design-vue";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import uploadImage from "@/components/upload.vue";
export default defineComponent({
  name: "EnterpriseForm",
  components: {
    uploadImage,
    // ExclamationCircleOutlined
  },
  setup() {
    const formRef = ref();
    const route = useRoute();
    const rules = {
      form_year: [{ required: true, message: "请选择年份", trigger: "change" }],
      form_month: [
        { required: true, message: "请选择月份", trigger: "change" },
      ],
      form_rysj: [
        { required: true, message: "请选择如园时间", trigger: "change" },
      ],
      form_bgmj: [
        { required: true, message: "请输入办公面积", trigger: "change" },
      ],
      form_bgrs: [
        { required: true, message: "请输入办公人数", trigger: "change" },
      ],
      form_yysr: [
        { required: true, message: "请输入营业收入", trigger: "change" },
      ],
      form_nse: [
        { required: true, message: "请输入纳税额", trigger: "change" },
      ],
      form_fwrc: [
        { required: true, message: "请输入服务人次", trigger: "change" },
      ],
      form_fwdw: [
        { required: true, message: "请输入服务用人单位", trigger: "change" },
      ],
      form_zphhj: [
        { required: true, message: "请输入举办招聘会场次", trigger: "change" },
      ],
      form_zphxs: [
        {
          required: true,
          message: "请输入举办线上招聘会场次",
          trigger: "change",
        },
      ],
      form_zphxx: [
        {
          required: true,
          message: "请输入举办线下招聘会场次",
          trigger: "change",
        },
      ],
      form_qzxx: [
        {
          required: true,
          message: "请输入发布求职信息条数",
          trigger: "change",
        },
      ],
      form_jygw: [
        { required: true, message: "请输入提供就业岗位", trigger: "change" },
      ],
      form_jyyx: [
        { required: true, message: "请输入达成就业意向", trigger: "change" },
      ],
      form_jyrs: [
        { required: true, message: "请输入带动就业人数", trigger: "change" },
      ],
      form_pxb: [
        { required: true, message: "请输入举办培训班场次", trigger: "change" },
      ],
      form_pxrs: [
        { required: true, message: "请输入培训人数", trigger: "change" },
      ],
      form_gccrc: [
        { required: true, message: "请输入高层次人才", trigger: "change" },
      ],
    };
    const pageData = reactive({
      colon: false,
      autosize: false,
      formId: null,
      form_year: null,
      enterprise_name: null,
      form: {
        // form_income: null,
        // form_tax: null,
        // form_employment: null,
        form_year: null,
        form_month: null,
        enterprise_name: null,
        form_rysj: null,
        form_bgmj: null,
        form_bgrs: null,
        form_yysr: null,
        form_nse: null,
        form_fwrc: null,
        form_fwdw: null,
        form_zphhj: null,
        form_zphxs: null,
        form_zphxx: null,
        form_qzxx: null,
        form_jygw: null,
        form_jyyx: null,
        form_jyrs: null,
        form_pxb: null,
        form_pxrs: null,
        form_gccrc: null,
        form_attachment: "",
      },
      loadingBtn: false,
      loadingForm: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
    });
    const getEnterpriseFormDataFun = (_formId) => {
      getEnterpriseFormData({ form_id: _formId, user_id: pageData.user_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.form = { ...res.data.enterprise_form };
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error("网络有误，请稍后再试");
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const updateEnterpriseFormDataFun = () => {
      formRef.value
        .validate()
        .then((res) => {
          pageData.loadingBtn = true;
          pageData.loadingForm = true;
          updateEnterpriseFormData({
            ...res,
            user_id: pageData.user_id,
            form_id: pageData.formId,
          }).then((res) => {
            message.success("提交成功");
            url.replaceTo("/enterprise/formList");
          });
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loadingBtn = false;
          pageData.loadingForm = false;
        });
    };
    const handleSubmit = (e) => {
      e.preventDefault();
      updateEnterpriseFormDataFun();
    };
    watch(
      () => route.query,
      (val) => {
        if (route.path == "/enterprise/formDetail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.formId = query[0];
            getEnterpriseFormDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.form = null;
            message.error("暂无填报信息");
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      ...toRefs(pageData),
      formRef,
      handleSubmit,
      rules,
    };
  },
});
</script>
<style lang="less">
.form-apply-page {
  .form-wrap {
    width: 1000px;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-bottom: 20px;
  }
}
</style>
